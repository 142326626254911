<template>
    <div>
        <header-menu ref="HeaderMenu"></header-menu>
        <div class="view-content">
            <div class="banner">
                <div class="desc__box text_left">
                    <h1 class="banner_title b_b_space font-size_60">
                        <div>辖区与指标管理系统</div>
                    </h1>
                    <div class="banner_desc font-size_20">
                        <p class="b_b_space space_p_b_one">
                            科学合理的组织架构及辖区指标管理系统。能适应各种营销模式的辖区管理及指标下达，根据市场规模合理布局各辖区人员配置，及时优化绩效指标。
                        </p>
                    </div>
                </div>
            </div>
            <div class="l_r_space t__space">
                <h2 class="nav__title">核心功能</h2>
                <div class="tab_Jurisdiction">
                    <a-tabs
                        default-active-key="1"
                        :tabBarGutter="120"
                        :tabBarStyle="{
                            borderBottom: 'none',
                        }"
                    >
                        <a-tab-pane key="1" tab="组织架构维护">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                        :xs="{ span: 24 }"
                                        :sm="{ span: 24 }"
                                        :md="{ span: 9, offset: 3 }"
                                        :lg="{ span: 9, offset: 3 }"
                                        :xl="{ span: 9, offset: 3 }"
                                        :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        style="max-width: 540px"
                                        :src="tabImg.soft_7"
                                        alt="组织架构维护"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">
                                        组织架构维护
                                    </p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            合理灵活的组织架构体系，通过人员、职位、岗位、部门的组合配置，可以支持复杂多变的业务需求。各区域人员配置清晰，并可支持历史组织架构查询，即使人员流失也可进行追溯，保证数据的体系性完整。
                                        </p>
                                    </div>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="客户关系维护">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                        :xs="{ span: 24 }"
                                        :sm="{ span: 24 }"
                                        :md="{ span: 9, offset: 3 }"
                                        :lg="{ span: 9, offset: 3 }"
                                        :xl="{ span: 9, offset: 3 }"
                                        :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                            style="max-width: 540px"
                                        :src="tabImg.soft_8"
                                        alt="客户关系维护"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">
                                        客户关系维护
                                    </p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            维护客户与辖区关系，从执行层面各业务员可以权限设定，管理层可查看各辖区的客户分布情况。记录每条客户关系的生效时间，可对历史客户关系进行追溯，即使组织架构发生调整，依然可以查询到客户历史的维护关系。
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="3" tab="绩效指标管理">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                        :xs="{ span: 24 }"
                                        :sm="{ span: 24 }"
                                        :md="{ span: 9, offset: 3 }"
                                        :lg="{ span: 9, offset: 3 }"
                                        :xl="{ span: 9, offset: 3 }"
                                        :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                            style="max-width: 540px"
                                        :src="tabImg.soft_9"
                                        alt="绩效指标管理"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">
                                        绩效指标管理
                                    </p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            多种下达方式，可按照数量、金额、岗位、终端、地区等维度与方式下达指标，并可按月进行灵活调整。可按各层级下达指标，辖区、地区、大区支持多种考核指标管理模型。
                                        </p>
                                        <p>
                                            指标达成可视化分析报告，助力企业决策依据。
                                        </p>
                                    </div>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <div class="l_r_space t__space" style="background-color: #eee">
                <h2 class="nav__title">为什么选择正也科技</h2>
                <h3 class="nav__desc" style="padding: 0.1rem 0 0.4rem 0">
                    行业有深度 数据有态度
                </h3>
                <div>
                    <a-row :gutter="20" type="flex">
                        <a-col
                            style="padding: 0 0.1rem 20px 0.1rem"
                            :xs="24"
                            :sm="12"
                            :md="12"
                            :lg="8"
                            :xl="8"
                            :xxl="8"
                            v-for="(item, index) in softWareList"
                            :key="index"
                        >
                            <a-card>
                                <a-row style="align-items: center" type="flex">
                                    <a-col
                                        :xs="24"
                                        :sm="10"
                                        :md="10"
                                        :lg="10"
                                        :xl="10"
                                        :xxl="10"
                                        ><img
                                            :src="item.img_url"
                                            :alt="item.title"
                                            style="width: 100%; max-width: 80px"
                                    /></a-col>
                                    <a-col
                                        :xs="24"
                                        :sm="14"
                                        :md="14"
                                        :lg="14"
                                        :xl="14"
                                        :xxl="14"
                                        ><div style="min-height: 1.1rem">
                                            <h3
                                                class="soft_title text_left b_b_space font-size_26"
                                            >
                                                {{ item.title }}
                                            </h3>
                                            <p
                                                class="soft_desc text_left b_b_space font-size_16"
                                            >
                                                {{ item.desc }}
                                            </p>
                                        </div></a-col
                                    >
                                </a-row>
                            </a-card>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Footer from '@/components/Footer'
import HeaderMenu from '@/components/HeaderMenu'
import soft_1 from '@/assets/img/png/soft_1.png'
import soft_2 from '@/assets/img/png/soft_2.png'
import soft_3 from '@/assets/img/png/soft_3.png'
import soft_4 from '@/assets/img/png/soft_4.png'
import soft_5 from '@/assets/img/png/soft_5.png'
import soft_6 from '@/assets/img/png/soft_6.png'
import soft_7 from '@/assets/img/png_new/soft_7.png'
import soft_8 from '@/assets/img/png_new/soft_8.png'
import soft_9 from '@/assets/img/png_new/soft_9.png'
export default {
    name: 'Jurisdiction',
    beforeCreate() {},
    components: { Footer, HeaderMenu },
    data() {
        return {
            softWareList: [
                {
                    title: '行业深度',
                    desc:
                        '正也科技核心成员为医药营销管理咨询专家和多年医药行业软件开发实施经验。',
                    img_url: soft_1,
                },
                {
                    title: '资源丰富',
                    desc:
                        '公司拥有海量行业主数据供客户选择，以及丰富的关键管理识别经验',
                    img_url: soft_2,
                },
                {
                    title: '产品规范',
                    desc:
                        'S2P支持全面数据颗粒化、追求灵活配置，应对企业未来管理需求',
                    img_url: soft_3,
                },
                {
                    title: '敏捷开发',
                    desc:
                        '基于主数据平台深度颗粒化，不断快速迭代，满足企业的个性化需求',
                    img_url: soft_4,
                },
                {
                    title: '致力创新',
                    desc:
                        'S2P专注于研究“营销方法论”、“营销新算法”，“让数据有态度”',
                    img_url: soft_6,
                },
                // {
                //   title: "致力于创新",
                //   desc: "公司专注于研究如何使用信息化的方法，以“数”助销",
                //   img_url: soft_6,
                // },
            ],
            tabImg: { soft_7, soft_8, soft_9 },
        }
    },
    created() {},
    methods: {
        handleSubmit() {},
    },
}
</script>
<style scoped lang="scss">
@import '@/assets/style/color.scss';
.soft_title {
    padding: 0.15rem 0 0.1rem 0;
}
.soft_desc {
    color: $color-fixGray;
}
.tab_Jurisdiction {
    .Jurisdiction_desc {
        width: 100%;
    }
}
.banner {
    width: 100%;
    position: relative;
    background: url('~@/assets/img/png/Jurisdiction_banner.png');
    background-size: 100% 100%;
    height: 8rem;
    overflow: hidden;
    .desc__box {
        position: absolute;
        top: 1.2rem;
        left: 13.5%;
        .banner_desc {
            width: 6rem;
            padding-top: 0.3rem;
        }
    }
}
.space_p_b_one {
    padding-bottom: 0.1rem;
}
.row_view-box {
    align-items: center;
    padding: 0rem 0 0.8rem 0;
}
</style>
